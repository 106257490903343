import { render, staticRenderFns } from "./FullForm.vue?vue&type=template&id=52f27768&scoped=true&"
import script from "./FullForm.vue?vue&type=script&lang=js&"
export * from "./FullForm.vue?vue&type=script&lang=js&"
import style0 from "./FullForm.vue?vue&type=style&index=0&id=52f27768&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52f27768",
  null
  
)

export default component.exports